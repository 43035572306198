@import "../../../styles/variables.scss";

.react-datepicker {
    width: 110%;
    border-radius: 22px !important;
}

.react-datepicker__header{
    background-color: white !important;
    height: 25%;
    border-bottom: none !important;
    border-radius: 22px !important;
}

.react-datepicker__month-container{
    width: 100%;
    height: 100%;
}

.react-datepicker__month{
    height: 75%;
}

.react-datepicker__day-names{
    display: flex;
    justify-content: space-around;
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    font-size: 16px;
    padding-top: 18px;
}

.react-datepicker__day-name{
    color:  #9197A6 !important;
}

.react-datepicker__week{
    display: flex;
    justify-content: space-around;
    height: 20%;
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    font-size: 16px;
}

.react-datepicker__current-month {
    visibility: hidden;
}

.react-datepicker__header__dropdown {
    font-family: "OurLexend-Regular", Helvetica, sans-serif;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 8px;
}

.react-datepicker__day--selected{
    color: #EF4F5F !important;
    background-color: #f897a0 !important;
}
// font-weight: normal;


.react-datepicker__day {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px !important;
    border-radius: 50% !important;
}

.react-datepicker__day:hover{
    background-color: #f897a0 !important;
}

.react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none;
}

.react-datepicker__navigation {
    visibility: hidden;
}

.react-datepicker__month-read-view--down-arrow {
    border-color: #EF4F5F !important;
    top: 3px !important;
}

.react-datepicker__year-read-view--down-arrow{
    border-color: #EF4F5F !important;
    top: 3px !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: white !important;
}

.react-datepicker__day--today {
    font-weight: normal !important;
}

.react-datepicker__month-dropdown {
    width: 80% !important;
    position: absolute !important;
    top: 0 !important;
    left: 11px !important;
}

.react-datepicker__navigation--years {
    height: 1px !important;
}

.react-datepicker__year-dropdown {
    width: 60% !important;
    position: absolute !important; 
    top: 0 !important;
    left: 100px !important;
}