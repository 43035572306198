// imports
@import "../../styles/variables.scss";

// ** pos outlet selection modal css ** //
.posOutletSelectionModal{
    >div:nth-child(1){
        background-color: rgba(0,0,0,0.7);
    }
    >div:nth-child(2) {
        display: flex;
        flex-direction: column;
        border-radius: 24px;
        width: 540px;
        height: 800px;
        background-color: $indigo-cool-50;

        >section:nth-child(1) {
            display: flex;
            margin-bottom: 2.2rem;

            h2 {
                font-size: 30px;
                color: $sushi-gray-dark;
                font-weight: 700;
            }
        }

        >section:nth-child(2) {
            display: flex;
            justify-content: center;
            flex-grow:1;
            overflow-y: auto;
        }

        @media only screen and (max-width: 768px) {
            width: 100vw;
            height: 100%;
            border-radius: 0;
        }
    }

    .searchableInput{
        img{
            height: 16px;
            width: 16px;
            margin-left: 8px;
        }
        label {
            margin-left: 10px;
            top: 10px;
        }
        input {
            border-radius: 100px !important;
            padding-left: 40px !important;
            border: 1px solid $grey-200;
        }
        input:focus {
            border: 2px solid $red-500;
        }
    }

    .noOutletInfo{
        border: 1px solid $grey-200;
        padding: 20px;
        border-radius: 20px;
    }

    .posAccountName{
        border-bottom: 1px solid $grey-200;
    }

    .inactiveTag {
        background: #FFEDEF;
        border-radius: 6px;
        padding: 4px 8px;
        color: $red-700;
        width: fit-content;
    }

    .posOutletListContainer{
        box-shadow: 0px 1px 4px 0px #1C1C1C0F;
        border-radius: 20px;
        padding: 24px;
    }

    .dashedLine{
        height:1px;
        width: 100%;
        border: 1.5px dashed $cool-gray-100;
    }

    .posLinkContainer{
        margin-top: -12px;
    }

    .posLinkIcon{
        padding: 12px;
        border-radius: 48px;
        border: 12px solid $indigo-cool-50;
    }

    .posOutletsContainer{
        margin-top:-12px;

        >section:nth-child(1){

            >div:nth-child(1){
                border-radius: 24px;

                >div>section>h5{
                    font-size: 22px;
                    color: #293142;
                    font-weight: 700;
                }
            }
        }

        .posOutletContainer{
            >section:nth-child(2){
                margin: 0;

                label{
                    margin: 0;

                    span{
                        display: none;
                    }
                }
            }
        }
    }

    .posOutletSelectionModalFooter{
        border-radius: 0px 0px 24px 24px;
    }

    button{
        width: 100%;
        span span{
            font-family: "OurLexend-Regular", Helvetica, sans-serif !important;
        }
        >span:nth-child(1){
            border-radius: 12px;
        }
    }

    @media only screen and (max-width: 768px) {
        z-index: 1010 !important;
        position: fixed !important;
    }

}

// ** pos outlet selection modal css ** //