@import "../../../styles/variables.scss";
.mobileNumberFlag{
    height: 20px;
    width: 30px;
}

.flagPosition{
    position: absolute;
    top: 18px;
    left: 16px;
    z-index: 10;
}

.mobileNumberInput {
    input {
        padding: 10px 16px 10px 92px;
    }
}

.multipleInputsOptions{
    overflow-y: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

.dropdown{
    margin-bottom: 20px;
    div{
        div{
            div{
                border-radius: 12px !important;
                height: 56px;
                span{
                    border: 2px solid green($color: #000000);
                } 
            }
        }
        span:nth-child(2){
            margin-top: 10px;
            border-radius: 12px;
        } 
    }
}

menuitem {
    margin-top: 150px !important;
}

.datePicker{
    width: 100% !important;
    div{
         width: inherit;
         div{
            section{
                section{
                    position: relative;
                }
            }
         }
    }
}

.removeMargin{
    position: absolute;
    top: 55px;
}

.calendarIcon{
    width: 20px;
    height: 20px;
    position:absolute;
    right: 5%;
    top: -4px;
    @media screen and (max-width:992px) {
        width: 20px;
        height: 20px;
    }
}

.react-datepicker-wrapper{
    padding: 0;
    border: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0;
}

.pdr2{
    margin-right: 20%;
}

.disabledInputDropdown{
    input {
        background-color: #fff !important;
        color: black;
    }
}

.genericInput {
    >section:first-child {
        height: 56px;
        margin-top: 2%;
    }
    input {
        font-size: 16px !important;
        border-radius: 12px !important;
        height: 100%;
    }
    div {
        font-size: 14px !important;
        margin-top: 2px !important;
        margin-right: 4px !important;
    }
}

.gstManualInput{
    >section:first-child {
        height: 56px;
    }
    input {
        font-size: 16px !important;
        border-radius: 12px !important;
        height: 100% !important;
    }
    div {
        font-size: 14px !important; 
    }
    margin-top: 20px;
}

.otpInput {
    input {
        width: 48px !important;
        margin-right: 10px;
        border: 1px solid #D6D6D6;
        border-radius: 12px !important;
        height: 48px;
    }
}

.optionsBox{
    padding: 12px 12px;
    border: 1.5px solid  #E6E9EF;
    border-radius: 12px;
    gap: 8px;
    white-space: nowrap;
}

.selectedOptionBox{
    border: 1.5px solid var(--redred-500, #EF4F5F);
    background: var(--redred-50, #FFF5F6);
}

.inputIcon{
    margin-top: -1px !important;
}

.gstInputsBox{
    width: 432px;
    padding: 0px 20px;
    align-items: flex-start;
    border-radius: 20px;
    border: 1px solid var(--coolgreycoolgrey-200, #E6E9EF);
    background: var(--basewhite-000, #FFF);
    box-shadow: 0px 1px 4px 0px rgba(28, 28, 28, 0.06);
    @media screen and (max-width:992px) {
        width: 100%;
    }
}

.inputOptions{
    display: flex;
    padding: 20px 0px;
}

.dashedBorderDown{
    border-bottom: 1.5px dashed #F5F5F5;
}

.inputText{
    flex: 1 0 0;
}

.UploadInputsBox{
    padding: 20px 24px;
    border-radius: 20px;
    background: var(--basewhite-000, #FFF);
    width: 100%;
    // max-height: 402px;
    // overflow: scroll;
}

.UploadInputsLoader{
    height: 50%;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageContainer{
    padding: 20px 24px;
}

.dashedLine{
    border: none;
    height: 1px;
    background: #E6E9EF;
    background: repeating-linear-gradient(90deg,#E6E9EF,#E6E9EF 6px,transparent 6px,transparent 12px);
}

.inheritImage{
    width: 100%;
}

.UploadInputsBoxAbove{
    
    border: 1px solid var(--coolgreycoolgrey-200, #E6E9EF);
    border-radius: 20px;
    background: var(--basewhite-000, #FFF);
    margin-bottom: 24px;
    overflow: scroll;
}

.UploadInputsText{
    width: 200px;
    height: 80%;
}

.documentSubHeading{
    color: #767C8F
}

.documentButton{
    height: 40px;
    Button{
        height: 100% !important;
        span{
            font-size: 16px;
        }
    }
}

.UploadInputsErrorBox{
    height: 40px;
    background-color: #FFEDEF;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.UploadInputsErrorBoxTextAndImage{
    img{
        height: 16px;
        margin-right: 4px;
    }
    span{
        color:#BF2938;
    }
}

.verifiedText{
    color:$green-color-verified
}

.disabledInput {
    input {
        background-color: #fff !important;
        color: $gray-500;
    }
}

.scrollableBox{
     height: 300px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.detailsBox{
    width: 432px;
    flex-shrink: 0;
    border-radius: 20px;
    background: var(--blueblue-800, #0E3272);
    margin-bottom: 24px;
}

.documentAvatar{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    right: 20px;
    top: 20px;
}

.documentDetails{
    top: 20px;
    color: var(--basewhite-000, #FFF);
    left: 20px;
}

.documentDetailsSub{
    width: 247px;
    top: 45px;
    color: var(--basewhite-000, #FFF);
    left: 20px;
    opacity: 0.8;
    line-height: 20px;
}

.multipleInputScrollable{
    @media screen and (max-width:992px) {
        height: calc(100vh - 200px);
        scrollbar-width: none;
    }
}